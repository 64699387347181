import React from "react"

import Layout from "../components/layout"
import { FooterAlternate1 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = ({ location }) => (
    <Layout location={location}>
      <SEO title="Recent Press" />

      <article className="article">
    <header className="header header--basic article__header">
        <div className="header__content">
            <div className="container">
                <div className="row">
                    <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                        <h1 className="header__title">Recent Press</h1>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div className="container" id="article-content">
        <div className="row">
            <div className="article__body col-sm-12 col-md-8 col-lg-offset-1 col-lg-7">
                <div className="rich-text"><p><br/></p><p><b><a href="https://www.afr.com/work-and-careers/careers/linkedin-for-salespeople-launches-20200923-p55yjm">LinkedIn for salespeople launches</a></b><br/></p><p>October 13th, 2020 - From The Australian Financial Review</p><p><br /></p></div>
                <div className="rich-text"><p><b>The Official Launch of SalesTribe:</b></p></div>
                <div className="embed-responsive embed-responsive-16by9"><iframe title="youtube" width="480" height="270" src="https://www.youtube.com/embed/EB2UJFSPMjU?feature=oembed" frameborder="0" allowfullscreen></iframe>
</div>
                <div className="rich-text"><p><br/></p><p><b><a href="http://bit.ly/2nKLxMR">SalesTribe matches sales people to start-ups in need of professional sales people:</a></b><br/></p><p>March 15th, 2017 - From The StartUp Daily.</p><p><br/></p></div>
                <div className="rich-text"><p><b><a href="http://bit.ly/2mulBF2">SalesTribe is disrupting the way that small businesses acquire sales capability:</a></b><br/></p><p>March 16th, 2017 - From The Australian Business Review.</p><p><br/></p></div>
                <div className="rich-text"><p><b><a href="http://bit.ly/2o8Numl">SalesTribe signs first business customer: eBlueprint:</a></b></p><p>March 15th, 2017 - From ITWire.</p><p><br/></p></div>
                <div className="rich-text"><p><b><a href="http://bit.ly/2mO7cUa">Sales professionals are facing some serious challenges:</a></b><br/></p><p>February 28th, 2017 - From Inside Small Business.</p><p><br/></p></div>
                <div className="rich-text"><p><b><a href="http://bit.ly/2nyJaLM">New service connecting sales professionals and start-ups:</a></b></p><p>March 27th, 2017 - From Inside Small Business.</p><p><br/></p></div>
                <div className="rich-text"><p><b><a href="http://anthillonline.com/new-melbourne-startup-salestribe-selected-u-k-s-global-entrepreneur-programme/">SalesTribe selected on the UK's Global Entrepreneur Program.</a></b></p><p>April 4th, 2017 - AnthillOnline.com </p></div>
                
            </div>
        </div>
    </div>
</article>
      
      <FooterAlternate1 />

    </Layout>
)

export default IndexPage 
